<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Зміна пароля" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-input
                type="password"
                label="Існуючий пароль *"
                v-model="existingPassword"
                @input="errors = ''"
              />
              <va-input
                type="password"
                label="Новий пароль *"
                v-model="newPassword"
                @input="errors = ''"
              />
              <va-input
                type="password"
                label="Новий пароль ще раз *"
                v-model="newPasswordConfirmation"
                @input="errors = ''"
              />
              <div v-if="errors" style="color: red;">{{ errors }}</div>
              <va-button @click="submit()">Змінити</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'

export default {
  name: 'grid',
  data () {
    return {
      existingPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',

      errors: '',
    }
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
  },
  methods: {
    submit () {
      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/change-password`, {
        existing_password: this.existingPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.newPasswordConfirmation,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
